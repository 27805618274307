#home {
  position: relative;
  margin-bottom: 1rem;


  .app__wrapper {
    padding: 0;
    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 4rem 1rem 0;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding: 5rem 1rem;
  }
}

.app__header-info {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0 2rem;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;
    padding: 2rem;
    background: var(--white-color);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    text-align: justify;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.6;
      color: var(--brown-color);
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;
      color: var(--gray-color);
    }

    @media screen and (min-width: 2000px) {
      padding: 3rem;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1.25rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-bottom: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--white-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: 0.5s;

    &:hover {
      background-color: var(--secondary-color);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);

      svg {
        transform: scale(1.1);
        color: var(--primary-color);
      }
    }

    svg {
      width: 60%;
      height: 60%;
      transition: 0.5s;
      color: var(--secondary-color);
    }
  }

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 120px;
    height: 120px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }


  @media screen and (min-width: 2000px){
    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
    div:nth-child(2) {
      width: 70px;
      height: 70px;
    }
    div:nth-child(3) {
      width: 100px;
      height: 100px;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 0.5rem;
    }
    div:nth-child(1) {
      width: 70px;
      height: 70px;
    }
    div:nth-child(2) {
      width: 80px;
      height: 80px;
    }
    div:nth-child(3) {
      width: 60px;
      height: 60px;
    }
  }
}
.app__header-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  .app__header-img-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #95C082;
    width: 70%;
    transition: transform 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;

    &:hover {
      border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
    }
    @media screen and (max-width: 1000px) {
      width: 50%;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
  }
}
.badge-cmp, .tag-cmp {
  padding: 1rem 2rem;
  border: var(--white-color);
  border-radius: 15px;
  flex-direction: row;
  width: auto;
  margin: 0.5rem;

  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}