@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

:root {
    --font-base: "Rubik", sans-serif;

    --primary-color: #fafafb;
    --secondary-color: #95C082;
    --black-color: #030303;
    --lightGray-color: #e4e4e4;
    --gray-color: #A694A0;
    --brown-color: #46364a;
    --white-color: #ffffff;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}