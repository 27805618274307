.video-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
  .video-modal__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;

    .close-button {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
  .video-container {
   pointer-events: auto;
  }


